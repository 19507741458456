import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import LoadingButton from "../components/LoadingButton";
import { convertToBase64Image } from "../utils";

export default ({
  session,
  backendApi,
  activeOrgId,
  activeCompany,
  formOnly,
  onSave,
  fileId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompany: any;
  formOnly: boolean;
  onSave: () => void;
  fileId: string;
}) => {
  const [profile, setProfile] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!activeCompany.name) return;
    setProfile({
      id: activeCompany.id,
      publicProfile: activeCompany.publicProfile,
      name: activeCompany.name,
      description: activeCompany.description,
      website: activeCompany.website,
      email: activeCompany.email,
      phone: activeCompany.phone,
      logo_url: activeCompany.logo_url,
    });
  }, [activeCompany]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = async (event) => {
    const imageBase64 = await convertToBase64Image(event.target.files[0]);
    console.log("image", imageBase64);
    setProfile((prev) => {
      return {
        ...prev,
        logo_url: imageBase64,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await backendApi.putCompany(profile, activeOrgId);
    await onSave();
    setLoading(false);
  };

  return (
    <Box sx={{ maxWidth: 600, p: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ display: formOnly ? "none" : "block" }}
      >
        Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                name="publicProfile"
                value={profile.publicProfile}
                onChange={handleChange}
              />
            }
            label="Public Profile"
          />
        </FormGroup>
        <TextField
          fullWidth
          label="Company Name"
          name="name"
          value={profile.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={profile.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Website"
          name="website"
          value={profile.website}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={profile.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Phone Number"
          name="phone"
          value={profile.phone}
          onChange={handleChange}
          margin="normal"
        />
        <Box display="flex" alignItems="center" mt={2}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={fileId}
            multiple
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor={fileId}>
            <Button
              variant="outlined"
              component="span"
              startIcon={<PhotoCamera />}
            >
              Upload Logo
            </Button>
          </label>
          {profile.logo_url && (
            <Box sx={{ ml: 2 }}>
              <img height={45} width={45} src={profile.logo_url} />
            </Box>
          )}
        </Box>
        <LoadingButton loading={loading} type="submit" text="save" />
      </form>
    </Box>
  );
};
