import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";

const CollaboratorsDialog = ({
  open,
  onClose,
  canvasId,
  mode,
  supabase,
  session,
  activeOrgId,
  setInviteDialogOpen,
  refreshCollaborators,
}: {
  open: boolean;
  onClose: () => void;
  canvasId: string;
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  setInviteDialogOpen: (open: boolean) => void;
  refreshCollaborators: boolean;
}) => {
  const [collaborators, setCollaborators] = useState([]);
  const backendApi = new BackendApi(supabase, session);

  const getCollaborators = async () => {
    if (!open) return;
    const canvasUsers = await backendApi.getCanvasUsers(canvasId);
    setCollaborators(canvasUsers);
  };

  const roleMapping = {
    owner: "Owner",
    read: "Viewer",
    editor: "Editor",
    commentor: "Commentor",
  };

  useEffect(() => {
    getCollaborators();
  }, [canvasId, open, refreshCollaborators]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Collaborators</DialogTitle>
      <DialogContent>
        <List>
          {collaborators.map((collaborator) => (
            <ListItem key={collaborator.id}>
              <ListItemText
                sx={{ mr: 1 }}
                primary={
                  collaborator.organization_users.name ||
                  collaborator.organization_users.email
                }
              />
              <Chip label={roleMapping[collaborator.role]} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setInviteDialogOpen(true)}
        >
          Add Collaborator
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollaboratorsDialog;
