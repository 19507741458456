const colors = [
  "#FF6B6B", // Soft Red
  "#4ECDC4", // Turquoise
  "#45B7D1", // Sky Blue
  "#96CEB4", // Sage Green
  "#FFEEAD", // Light Yellow
  "#88D8B0", // Mint Green
  "#FF8B94", // Salmon Pink
  "#FFAAA5", // Peach
  "#FFD3B6", // Light Peach
  "#DCEDC1", // Light Green
  "#A8E6CF", // Soft Mint
  "#B6A6CA", // Lavender
  "#FFB5E8", // Pink
  "#85C1E9", // Light Blue
  "#F7DC6F", // Mellow Yellow
  "#B2DFDB", // Seafoam
  "#D7BDE2", // Light Purple
  "#F8C471", // Light Orange
  "#FAD7A0", // Pale Orange
  "#E8DAEF", // Soft Violet
];

export const createUniqueColorForId = (id) => {
  const hash = id.split("").reduce((hash, char) => {
    const charCode = char.charCodeAt(0);
    return ((hash << 5) - hash + charCode) | 0;
  }, 0);

  // Use absolute value to ensure positive number
  return colors[Math.abs(hash) % colors.length];
};

const getCategoryColor = (parentColorMap, categoryMap, node) => {
  if (
    node &&
    node.data &&
    node.data.categories &&
    node.data.categories.length > 0
  ) {
    //parentColorMap[node.id] = parentColorMap[node.data.categories[0].id];
    const categories = node.data.categories.filter((x) => parentColorMap[x.id]);
    const otherCateogry = categories.find(
      (x) => parentColorMap[x.id].name === "Other"
    );
    const notOtherCategory = categories.find(
      (x) => parentColorMap[x.id].name !== "Other"
    );
    const selectedParentNode = notOtherCategory
      ? parentColorMap[notOtherCategory.id]
      : parentColorMap[otherCateogry.id];
    if (categoryMap.hasOwnProperty(selectedParentNode.name)) {
      categoryMap[selectedParentNode.name].nodes.push({ node });
    } else {
      categoryMap[selectedParentNode.name] = {
        nodes: [{ node }],
        color: selectedParentNode.color,
        checked: true,
      };
    }
    parentColorMap[node.id] = selectedParentNode.color;
    return selectedParentNode.color;
  }
  const otherCategory: any = Object.keys(parentColorMap).find(
    (x: any) => parentColorMap[x].name === "Other"
  );
  if (otherCategory) {
    const selectedParentNode = parentColorMap[otherCategory];
    if (categoryMap.hasOwnProperty(selectedParentNode.name)) {
      categoryMap[selectedParentNode.name].nodes.push({ node });
    } else {
      categoryMap[selectedParentNode.name] = {
        nodes: [{ node }],
        color: selectedParentNode.color,
        checked: true,
      };
    }
    parentColorMap[node.id] = selectedParentNode.color;
    return selectedParentNode.color;
  }
  return "#2196f3";
};

const getEdgeColor = (parentColorMap, edge) => {
  return parentColorMap[edge.source] || "#2196f3";
};

export const highLightNodes = (
  setNodes,
  setEdges,
  setCategoryMap,
  categories
) => {
  const categoryMap = {};
  const parentColorMap = {};
  categories.forEach((category) => {
    const parentColor = createUniqueColorForId(category.id);
    parentColorMap[category.id] = { color: parentColor, name: category.name };
    if (category.children) {
      category.children.forEach((child) => {
        parentColorMap[child.id] = { color: parentColor, name: category.name };
        if (child.children) {
          child.children.forEach((ch) => {
            parentColorMap[ch.id] = { color: parentColor, name: category.name };
          });
        }
      });
    }
  });

  setNodes((n) =>
    n.map((node) => {
      const nodeColor = getCategoryColor(parentColorMap, categoryMap, node);
      return {
        ...node,
        style: {
          borderRadius: 10,
          background: nodeColor,
          boxShadow: `0 0 50px ${nodeColor}`,
        },
      };
    })
  );
  setEdges((e) =>
    e.map((edge) => {
      return { ...edge, style: { stroke: getEdgeColor(parentColorMap, edge) } };
    })
  );
  setCategoryMap(categoryMap);
};

const createColorMap = (nodeColorMap, categoryMap) => {
  Object.values(categoryMap).forEach((cat: any) => {
    cat.nodes.forEach((n) => {
      nodeColorMap[n.node.id] = cat.color;
    });
  });
};

const createOpacityMap = (ocacityMap, categoryMap) => {
  Object.values(categoryMap).forEach((cat: any) => {
    cat.nodes.forEach((n) => {
      ocacityMap[n.node.id] =
        cat.checked || cat.checked === undefined ? 1 : 0.2;
    });
  });
};

export const addNodesToMap = (setNodes, setEdges, layerMap, setLayerMap) => {
  const nodeColorMap: any = {};
  const nodeMap = {};
  const uncategorizedColor = createUniqueColorForId("Uncategorized");
  Object.keys(layerMap).forEach((k) => {
    const layer = layerMap[k];
    layer.nodes.forEach((n) => {
      nodeColorMap[n.canvas_node_id] = layer.color;
    });
  });
  setNodes((nodes) =>
    nodes.map((n) => {
      const nodeColor = nodeColorMap[n.id] || uncategorizedColor;
      nodeMap[n.id] = n;
      return {
        ...n,
        style: {
          borderRadius: 10,
          background: nodeColor,
          boxShadow: `0 0 50px ${nodeColor}`,
        },
      };
    })
  );
  const removeKeys = [];
  Object.keys(layerMap).forEach((k) => {
    const layer = layerMap[k];
    layer.nodes = layer.nodes.map((n) => {
      const nn = { ...n, node: nodeMap[n.canvas_node_id] };
      delete nodeMap[n.canvas_node_id];
      return nn;
    });
    if (!layer.nodes.filter((x) => x.node !== undefined)) {
      removeKeys.push(k);
    }
  });
  console.log("removed", removeKeys);
  removeKeys.forEach((k) => {
    delete layerMap[k];
  });
  setEdges((e) =>
    e.map((edge) => {
      return { ...edge, style: { stroke: nodeColorMap[edge.source] } };
    })
  );
  if (layerMap["Uncategorized"]) {
    Object.values(nodeMap).forEach((x) => {
      layerMap["Uncategorized"].nodes.push({ node: x });
    });
  } else if (Object.keys(nodeMap).length > 0) {
    layerMap["Uncategorized"] = {
      checked: true,
      color: uncategorizedColor,
      nodes: Object.values(nodeMap).map((x) => {
        node: x;
      }),
    };
  }
  setLayerMap(layerMap);
};

export const updateColorsFromCategoryMap = (
  setNodes,
  setEdges,
  categoryMap
) => {
  const nodeColorMap: any = {};
  const opacityMap: any = {};
  createColorMap(nodeColorMap, categoryMap);
  createOpacityMap(opacityMap, categoryMap);
  setNodes((n) =>
    n.map((node) => {
      const nodeColor = nodeColorMap[node.id];
      const nodeOpacity = opacityMap[node.id];
      return {
        ...node,
        style: {
          opacity: nodeOpacity,
          borderRadius: 10,
          background: nodeColor,
          boxShadow: `0 0 50px ${nodeColor}`,
        },
      };
    })
  );
  setEdges((e) =>
    e.map((edge) => {
      return {
        ...edge,
        style: {
          stroke: nodeColorMap[edge.source] || "#2196f3",
          opacity: opacityMap[edge.source],
        },
      };
    })
  );
};
