export const hostname = window.location.origin;

export const convertToBase64Image = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create canvas and context
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    // Set canvas dimensions to 256x256
    canvas.width = 256;
    canvas.height = 256;

    img.onload = () => {
      if (!ctx) {
        reject(new Error("Failed to get canvas context"));
        return;
      }

      // Use object-fit: cover style behavior
      const scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height
      );

      const x = (canvas.width - img.width * scale) / 2;
      const y = (canvas.height - img.height * scale) / 2;

      // Clear canvas and draw image
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      // Convert to base64
      try {
        const base64String = canvas.toDataURL("image/png");
        resolve(base64String);
      } catch (error) {
        reject(new Error("Failed to convert to base64"));
      }
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    // Read file as Data URL
    const reader = new FileReader();
    reader.onload = (e) => {
      img.src = e.target?.result as string;
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };
    reader.readAsDataURL(file);
  });
};

export const formatDateTime = (isoString, format = "default") => {
  const date = new Date(isoString);

  const formats = {
    default: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    },
    shortDate: {
      month: "short",
      day: "numeric",
    },
    fullDate: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    },
    timeOnly: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    },
  };

  return date.toLocaleDateString("en-US", formats[format]);
};
