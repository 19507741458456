import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useState } from "react";
import BackendApi from "../BackendApi";

const projects = [
  {
    id: 1,
    title: "Supply Chain Sentinel",
    description:
      "Advanced analytics platform for optimizing end-to-end supply chain operations.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 2,
    title: "Retail Analytics Suite",
    description:
      "Real-time insights on consumer behavior and store performance metrics.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 3,
    title: "Smart Fleet Management",
    description:
      "IoT solutions for tracking and managing commercial fleets efficiently.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 4,
    title: "Healthcare Compliance Tracker",
    description:
      "Streamlined solution for managing healthcare regulations and compliance adherence.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 5,
    title: "Customer Success Platform",
    description:
      "Tools to enhance customer service and boost customer retention rates.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 6,
    title: "FinTech Payment Gateway",
    description:
      "Secure, scalable payment solutions for the financial services industry.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 7,
    title: "AI-driven Fraud Detection",
    description:
      "Utilizes machine learning to detect and prevent fraud in real time.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 8,
    title: "Digital Contract Management",
    description:
      "Streamlines the creation, execution, and management of digital contracts.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 9,
    title: "ERP Integration Platform",
    description:
      "Facilitates seamless integration of ERP systems across diverse departments.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 10,
    title: "Cloud Migration Consultant",
    description:
      "Tailored solutions for businesses moving their operations to the cloud.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 11,
    title: "Workforce Augmentation Tool",
    description:
      "Robotic process automation tools to enhance workforce productivity.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 12,
    title: "Corporate Training Portal",
    description:
      "Comprehensive e-learning solutions tailored for corporate environments.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 13,
    title: "Real Estate Portfolio Manager",
    description:
      "Data-driven insights and tools for managing large real estate portfolios.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 14,
    title: "B2B eCommerce Platform",
    description:
      "Customizable eCommerce solutions tailored for B2B sales processes.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 15,
    title: "Mobile Workforce Management",
    description:
      "App-based solutions for managing on-the-go employees in various industries.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 16,
    title: "Construction Project Planner",
    description:
      "Project management tools specifically designed for the construction industry.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 17,
    title: "Event Management System",
    description:
      "Software to manage logistics, ticketing, and guest interactions at corporate events.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 18,
    title: "Manufacturing Optimization Tool",
    description:
      "AI solutions to optimize manufacturing processes and increase efficiency.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 19,
    title: "Sustainable Energy Consultant",
    description:
      "Software for managing and consulting on renewable energy projects.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 20,
    title: "IT Security Compliance Audit",
    description:
      "Tools and services to ensure IT environments meet security standards.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 21,
    title: "Telecom Network Manager",
    description:
      "Advanced tools for managing and optimizing telecommunications networks.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 22,
    title: "Pharmaceutical R&D Tracker",
    description:
      "Software to track and manage research and development in the pharmaceutical industry.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 23,
    title: "Digital Advertising Platform",
    description:
      "Programmatic advertising tools to optimize digital marketing strategies.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 24,
    title: "Restaurant Reservation System",
    description:
      "Cloud-based system for managing restaurant bookings and customer flow.",
    linkProject: "#",
    linkCanvas: "#",
  },
  {
    id: 25,
    title: "Logistics Route Optimizer",
    description:
      "Software to optimize delivery routes and reduce transportation costs.",
    linkProject: "#",
    linkCanvas: "#",
  },
];

const ProjectCard = ({ title, description, linkProject, linkCanvas }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
        <Typography variant="body2">
          <Link href={linkProject}>Link to project</Link>
        </Typography>
        <Typography variant="body2">
          <Link href={linkCanvas}>Link to canvas</Link>
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default ({
  session,
  backendApi,
  activeOrgId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProjects = projects.filter(
    (project) =>
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <TextField
        fullWidth
        label="Search Projects"
        variant="outlined"
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 3 }}
      />
      <Grid container spacing={2}>
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project) => (
            <ProjectCard key={project.id} {...project} />
          ))
        ) : (
          <Typography>No projects found.</Typography>
        )}
      </Grid>
    </Box>
  );
};
