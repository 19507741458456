import StarIcon from "@mui/icons-material/Star";
import { Box, Rating, Typography } from "@mui/material";
import React from "react";

const ProductRating = ({ rating, reviews }) => {
  const ratingFloat = parseFloat(rating);
  return (
    <Box>
      <Box>
        <Rating
          name="rating"
          value={ratingFloat}
          precision={0.5}
          readOnly
          icon={<StarIcon fontSize="inherit" />}
          emptyIcon={<StarIcon fontSize="inherit" />}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1 }}>
            {ratingFloat.toFixed(1)} out of 5
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, color: "text.secondary" }}>
            ({reviews} reviews)
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductRating;
