import { Clear, PersonOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import InviteUserDialog from "./InviteUserDialog";

export default ({
  mode,
  supabase,
  session,
  activeOrgId,
  backendApi,
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  backendApi: BackendApi;
}) => {
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [tab, setTab] = useState("profile");
  const getUsers = async () => {
    if (!activeOrgId) return;
    const res = await backendApi.getOrganizationUsers(activeOrgId);
    setUsers(res);
  };

  const handleInviteUser = async (userData) => {
    // Handle inviting the user with the provided email and role
    await backendApi.inviteUserToOrganization(
      activeOrgId,
      userData.email,
      userData.role
    );
    await getUsers();
  };

  const userRoleChanged = async (user, role) => {
    await backendApi.updateUserRole(activeOrgId, user.id, user.email, role);
    await getUsers();
  };

  useEffect(() => {
    getUsers();
  }, [activeOrgId]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Users
        <Button
          sx={{ float: "right" }}
          variant="outlined"
          onClick={() => setInviteDialogOpen(true)}
        >
          Invite User
        </Button>
      </Typography>
      <InviteUserDialog
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        onInvite={handleInviteUser}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={index}>
              <TableCell>
                <PersonOutlined />
              </TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Select
                  value={user.role}
                  onChange={(e) => userRoleChanged(user, e.target.value)}
                  disabled={user.role === "owner"}
                  autoWidth
                >
                  {user.role === "owner" && (
                    <MenuItem value="owner">
                      <em>Owner</em>
                    </MenuItem>
                  )}
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"member"}>Member</MenuItem>
                  <MenuItem value={"viewer"}>Viewer</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <IconButton>
                  <Clear />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
