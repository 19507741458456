import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Session } from "@supabase/supabase-js";
import React, { useState } from "react";
import BackendApi from "../../BackendApi";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "20px auto",
  padding: theme.spacing(2),
}));

const PricingCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  height: "100%",
}));

const LeadsComponent = ({
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
}) => {
  // State management
  const [leadsType, setLeadsType] = useState("oneTime"); // oneTime or stream
  const [filterType, setFilterType] = useState("category"); // category or company
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });

  // Mock data - In real application, these would come from an API
  const categories = ["SaaS", "Cloud Infrastructure", "Security", "AI/ML"];
  const companies = ["Microsoft", "AWS", "Google Cloud", "Oracle"];

  // Price calculation - This would typically come from a backend service
  const calculatePrice = () => {
    const basePrice = leadsType === "oneTime" ? 499 : 999;
    return basePrice;
  };

  const handlePurchase = () => {
    // Implement purchase logic
    console.log("Purchase details:", {
      leadsType,
      filterType,
      filter: filterType === "category" ? selectedCategory : selectedCompany,
      dateRange: leadsType === "oneTime" ? dateRange : "ongoing",
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Purchase Leads
      </Typography>

      {/* Lead Type Selection */}
      <Box mb={4}>
        <Typography variant="body1" gutterBottom>
          Select Lead Type
        </Typography>
        <RadioGroup
          row
          value={leadsType}
          onChange={(e) => setLeadsType(e.target.value)}
        >
          <FormControlLabel
            value="oneTime"
            control={<Radio />}
            label="One-time Export"
          />
          <FormControlLabel
            value="stream"
            control={<Radio />}
            label="Real-time Stream"
          />
        </RadioGroup>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Filter Type Selection */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Filter By
        </Typography>
        <RadioGroup
          row
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          <FormControlLabel
            value="category"
            control={<Radio />}
            label="Product Category"
          />
          <FormControlLabel
            value="company"
            control={<Radio />}
            label="Company"
          />
        </RadioGroup>

        {/* Filter Selection */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>
            {filterType === "category" ? "Category" : "Company"}
          </InputLabel>
          <Select
            value={
              filterType === "category" ? selectedCategory : selectedCompany
            }
            onChange={(e) =>
              filterType === "category"
                ? setSelectedCategory(e.target.value)
                : setSelectedCompany(e.target.value)
            }
            label={filterType === "category" ? "Category" : "Company"}
          >
            {filterType === "category"
              ? categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))
              : companies.map((company) => (
                  <MenuItem key={company} value={company}>
                    {company}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Box>

      {/* Date Range Selection - Only for one-time export */}
      {leadsType === "oneTime" && (
        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Select Date Range
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Start Date"
              type="date"
              value={dateRange.start}
              onChange={(e) =>
                setDateRange({ ...dateRange, start: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              label="End Date"
              type="date"
              value={dateRange.end}
              onChange={(e) =>
                setDateRange({ ...dateRange, end: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Stack>
        </Box>
      )}

      <Divider sx={{ my: 3 }} />

      {/* Pricing Section */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Pricing Details
        </Typography>
        <Stack spacing={3}>
          <PricingCard>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {leadsType === "oneTime"
                  ? "One-time Export"
                  : "Real-time Stream"}
              </Typography>
              <Typography variant="h4" color="primary" gutterBottom>
                ${calculatePrice()}
                {leadsType === "stream" && "/month"}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {leadsType === "oneTime"
                  ? "Get immediate access to historical lead data"
                  : "Receive new leads in real-time as they become available"}
              </Typography>
              <Box mt={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Includes:
                </Typography>
                <Stack spacing={1}>
                  <Chip
                    label={`Filtered by ${
                      filterType === "category" ? "product category" : "company"
                    }`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                  <Chip
                    label={
                      leadsType === "oneTime"
                        ? "CSV & JSON export formats"
                        : "REST API & Webhook integration"
                    }
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                  <Chip
                    label="Complete lead profiles"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </Stack>
              </Box>
            </CardContent>
          </PricingCard>

          {/* Purchase Button */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handlePurchase}
            disabled={
              !(
                (filterType === "category" && selectedCategory) ||
                (filterType === "company" && selectedCompany)
              ) ||
              (leadsType === "oneTime" && (!dateRange.start || !dateRange.end))
            }
          >
            Purchase {leadsType === "oneTime" ? "Export" : "Subscription"}
          </Button>
          {/* Terms and Conditions */}
          <Alert severity="info" sx={{ mt: 2 }}>
            <Typography variant="body2">
              By purchasing, you agree to our terms of service and data usage
              policy.
              {leadsType === "stream" &&
                " Subscription can be cancelled anytime."}
            </Typography>
          </Alert>
        </Stack>
      </Box>
    </Box>
  );
};
export default LeadsComponent;
