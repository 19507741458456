import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HOSTNAME } from "../constants";

export default ({
  value,
  onChange,
  session,
}: {
  value: any;
  onChange: (e: any) => void;
  session: Session;
}) => {
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const res = await axios.get(`${API_HOSTNAME}/categories`, {
      headers: { Authorization: `Bearer ${session.access_token}` },
    });
    console.log(res);
    setCategories(res.data.categories);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel>Categories</InputLabel>
      <Select
        name="categories"
        multiple
        value={value}
        label="Categories"
        onChange={onChange}
      >
        {categories.map((category) => (
            category.children.map((child) => (
              <MenuItem value={child.id}>{child.name}</MenuItem>
            ))
        ))}
      </Select>
    </FormControl>
  );
};
