import React, { useState } from "react";

import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  TextField,
  debounce,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import BackendApi from "../../BackendApi";
import CompanyLogo from "../../company/CompanyLogo";

export default function SearchBar({
  session,
  supabase,
  selectedOption,
  onDragStart,
}: {
  session: Session;
  supabase: SupabaseClient;
  selectedOption: (e: any, option: any) => void;
  onDragStart: (e: any, id: any, product: any) => void;
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const backendApi = new BackendApi(supabase, session);

  const inputChanged = async (e: any) => {
    if (e.target.value.length === 0) return;
    setLoading(true);
    const results = await backendApi.searchProducts(e.target.value);
    setLoading(false);
    setOptions(
      results.map((x) => {
        return { ...x, title: x.name, label: x.name };
      })
    );
  };
  const searchDelayed = debounce(inputChanged, 700);

  return (
    <Autocomplete
      id="search"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      renderOption={(props, option) => (
        <ListItem
          {...props}
          onDragStart={(event) => onDragStart(event, option.id, option)}
          draggable
        >
          <ListItemAvatar>
            <CompanyLogo product={option} sx={{}} />
          </ListItemAvatar>
          {option.name}
        </ListItem>
      )}
      filterOptions={(options, state) => options}
      onInputChange={searchDelayed}
      onChange={selectedOption}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Enter product name, software category, service name..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
