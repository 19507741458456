import { Add } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import CreateCompanyDialog from "./CompanyDialog";
import CompanyProductMetrics from "./CompanyProductMetrics";
import CompanyProducts from "./CompanyProducts";
import CompanyProfile from "./CompanyProfile";
import Campaigns from "./campaign/Campaigns";
import Leads from "./leads/Leads";

export default ({
  mode,
  supabase,
  session,
  activeOrgId,
  activeOrgUserId,
  backendApi,
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  activeOrgUserId: string;
  backendApi: BackendApi;
}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuItem, setMenuItem] = useState("dashboard");
  const [activeCompanyId, setActiveCompanyId] = useState("");
  const [activeCompany, setActiveCompany] = useState({});
  const [organizationCompanies, setOrganizationCompanies] = useState([]);
  const [createCompanyOpen, setCreateCompanyOpen] = useState(false);

  const theme = useTheme();

  const getCompanies = async () => {
    if (!session || !session.user || !activeOrgId) return;
    const res = await backendApi.getCompanies(session.user.id, activeOrgId);
    const companies = res;
    setOrganizationCompanies(companies);
    if (activeCompanyId && companies.length !== 0) {
      setActiveCompanyId(activeCompanyId);
      const activeCompany = companies.find((x) => x.id === activeCompanyId);
      setActiveCompany(activeCompany);
    } else if (companies.length !== 0) {
      setActiveCompanyId(companies[0].id);
      setActiveCompany(companies[0]);
    } else {
      setCreateCompanyOpen(true);
    }
  };

  const activeCompanyChange = (e: any) => {
    if (e.target.value === "create-company") {
      setCreateCompanyOpen(true);
      return;
    }
    supabase.auth.updateUser({
      data: {
        active_company_id: e.target.value,
      },
    });
    setActiveCompanyId(e.target.value);
    setActiveCompany(
      organizationCompanies.find((x) => x.id === e.target.value)
    );
  };

  useEffect(() => {
    getCompanies();
    if (session && session.user && session.user.user_metadata) {
      setActiveCompanyId(session.user.user_metadata.active_company_id);
    }
  }, [session, activeOrgId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CreateCompanyDialog
        open={createCompanyOpen}
        session={session}
        backendApi={backendApi}
        activeOrgId={activeOrgId}
        activeCompanyId={activeCompanyId}
        onClose={() => {
          setCreateCompanyOpen(false);
          getCompanies();
        }}
        onSave={() => getCompanies()}
      />
      <Box
        sx={{
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: 0,
          borderRight:
            theme.palette.mode === "dark"
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : "1px solid rgba(0, 0, 0, 0.12)",
          visibility: "hidden",
          whiteSpace: "nowrap",
          ...(menuOpen && {
            width: 250,
            visibility: "visible",
            transition: theme.transitions.create(
              ["margin", "width", "visibility"],
              {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }
            ),
          }),
        }}
      >
        <Box sx={{ p: 1 }}>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>Company</InputLabel>
            <Select
              label="Company"
              fullWidth
              value={activeCompanyId}
              onChange={activeCompanyChange}
            >
              {organizationCompanies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
              <MenuItem color="primary" value="create-company">
                <Add sx={{ mr: 1 }} />
                <ListItemText primary="Create Company" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <List>
          <ListItemButton
            selected={menuItem === "dashboard"}
            disabled={menuItem === "dashboard"}
            onClick={() => setMenuItem("dashboard")}
          >
            Dashboard
          </ListItemButton>
          <ListItemButton
            disabled={menuItem === "profile"}
            selected={menuItem === "profile"}
            onClick={() => setMenuItem("profile")}
          >
            Profile
          </ListItemButton>
          <ListItemButton
            disabled={menuItem === "products"}
            selected={menuItem === "products"}
            onClick={() => setMenuItem("products")}
          >
            Products
          </ListItemButton>
          <ListItemButton
            disabled={menuItem === "campaigns"}
            selected={menuItem === "campaigns"}
            onClick={() => setMenuItem("campaigns")}
          >
            Campaigns
          </ListItemButton>
          <ListItemButton
            disabled={menuItem === "leads"}
            selected={menuItem === "leads"}
            onClick={() => setMenuItem("leads")}
          >
            Leads
          </ListItemButton>
        </List>
      </Box>
      <Box sx={{ height: "calc(100vh - 67px)", width: "100%" }}>
        <Box sx={{ display: menuItem === "dashboard" ? "block" : "none" }}>
          <CompanyProductMetrics
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
            activeCompanyId={activeCompanyId}
          />
        </Box>
        <Box sx={{ display: menuItem === "profile" ? "block" : "none" }}>
          <CompanyProfile
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
            activeCompany={activeCompany}
            formOnly={false}
            onSave={() => {}}
            fileId="company-file-select"
          />
        </Box>
        <Box sx={{ display: menuItem === "products" ? "block" : "none" }}>
          <CompanyProducts
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
            activeCompanyId={activeCompanyId}
          />
        </Box>
        <Box sx={{ display: menuItem === "campaigns" ? "block" : "none" }}>
          <Campaigns
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
            activeCompanyId={activeCompanyId}
          />
        </Box>
        <Box sx={{ display: menuItem === "leads" ? "block" : "none" }}>
          <Leads
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
            activeCompanyId={activeCompanyId}
          />
        </Box>
      </Box>
    </Box>
  );
};
