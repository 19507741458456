import { styled } from "@mui/material";
import React from "react";
import { MiniMap } from "reactflow";
import { darkTheme, lightTheme } from "../theme";

const LightMiniMapStyled = styled(MiniMap)`
  background-color: ${(props) => lightTheme.bg};

  .react-flow__minimap-mask {
    fill: ${(props) => lightTheme.minimapMaskBg};
  }

  .react-flow__minimap-node {
    fill: ${(props) => lightTheme.nodeBg};
    stroke: none;
  }
`;

const DarkMiniMapStyled = styled(MiniMap)`
  background-color: ${(props) => darkTheme.bg};

  .react-flow__minimap-mask {
    fill: ${(props) => darkTheme.minimapMaskBg};
  }

  .react-flow__minimap-node {
    fill: ${(props) => darkTheme.nodeBg};
    stroke: none;
  }
`;

export default ({ mode }: { mode: string }) => {
  return mode === "light" ? (
    <LightMiniMapStyled
      sx={{ display: { xs: "none", md: "none", lg: "block" } }}
    />
  ) : (
    <DarkMiniMapStyled
      sx={{ display: { xs: "none", md: "none", lg: "block" } }}
    />
  );
};
