import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CategorySelect from "../components/CategorySelect";
import LoadingButton from "../components/LoadingButton";
import { convertToBase64Image } from "../utils";

export default ({
  open,
  handleClose,
  initialData,
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
  fileId=''
}) => {
  const [previewLogo, setPreviewLogo] = useState("");
  const [product, setProduct] = useState<any>({
    name: "",
    description: "",
    value: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData) {
      console.log(initialData);
      setProduct({
        id: initialData.id,
        name: initialData.name,
        description: initialData.description,
        value: initialData.value,
        company_id: initialData.companyId,
        categories: initialData.categories.map((x) => x.id),
        logo_url: initialData.logo_url,
      });
    }
  }, [initialData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleProductImageChange = (event) => {
    convertToBase64Image(event.target.files[0]).then((imageBase64) => {
      setProduct({
        ...product,
        logo_url: imageBase64,
      });
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await backendApi
      .putProduct(product, activeOrgId, activeCompanyId)
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {initialData ? "Edit Product" : "Create New Product"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    name="public_profile"
                    value={product.publicProfile}
                    onChange={handleChange}
                  />
                }
                label="Public Profile"
              />
            </FormGroup>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Product Name"
              value={product.name}
              onChange={handleChange}
            />

            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              multiline
              rows={4}
              value={product.description}
              onChange={handleChange}
            />

            <CategorySelect
              value={product.categories || []}
              onChange={handleChange}
              session={session}
            />

            <Box display="flex" alignItems="center" mt={2}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file-product"
                type="file"
                onChange={handleProductImageChange}
              />
              <label htmlFor="raised-button-file-product">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Upload Logo
                </Button>
              </label>
              {product.logo_url && (
                <Box sx={{ ml: 2 }}>
                  <img height={45} width={45} src={product.logo_url} />
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ mt: 3, mb: 2, mr: 1 }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            text={initialData ? "Update" : "Create"}
            loading={loading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
