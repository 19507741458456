import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  DragIndicator,
  Save,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackendApi from "../../BackendApi";
import CompanyLogo from "../../company/CompanyLogo";
import NewLayerDialog from "./NewLayerDialog";
import {
  addNodesToMap,
  createUniqueColorForId,
  highLightNodes,
  updateColorsFromCategoryMap,
} from "./highlight_categories";

export default ({
  open,
  onClose,
  layerMap,
  setLayerMap,
  setNodes,
  setEdges,
  backendApi,
  activeCanvasId,
  categories,
}: {
  open: boolean;
  onClose: () => void;
  layerMap: any;
  setLayerMap: Function;
  setNodes: Function;
  setEdges: Function;
  backendApi: BackendApi;
  activeCanvasId: string;
  categories: any;
}) => {
  const theme = useTheme();
  const [createLayerOpen, setCreateLayerOpen] = useState(false);
  const [layersLoading, setLayersLoading] = useState(false);
  const [layersDeleted, setLayersDeleted] = useState([]);

  const onDragEnd = (event) => {
    console.log("on drag end", event);
    const node = layerMap[event.source.droppableId].nodes.find(
      (x) => x.node.id === event.draggableId
    );
    layerMap[event.source.droppableId].nodes.splice(event.source.index, 1);
    layerMap[event.destination.droppableId].nodes.splice(
      event.destination.index,
      0,
      node
    );
    setLayerMap(layerMap);
    updateColorsFromCategoryMap(setNodes, setEdges, layerMap);
  };

  const onCheckboxChange = (event, category) => {
    layerMap[category].checked = event.target.checked;
    setLayerMap(layerMap);
    updateColorsFromCategoryMap(setNodes, setEdges, layerMap);
  };

  const deleteLayer = (layerName: string, layer: any) => {
    if (layer.id) {
      setLayersDeleted([...layersDeleted, layer]);
    }
    delete layerMap[layerName];
    setLayerMap({ ...layerMap });
  };

  const handleCreateNewLayer = (layerName: string) => {
    const randomColor = createUniqueColorForId(layerName);

    const updatedLayerMap = {
      [layerName]: {
        color: randomColor,
        checked: true,
        nodes: [],
      },
      ...layerMap,
    };

    setLayerMap(updatedLayerMap);
    updateColorsFromCategoryMap(setNodes, setEdges, updatedLayerMap);
    setCreateLayerOpen(false);
  };

  const saveLayers = async () => {
    console.log("Saving layers");
    setLayersLoading(true);
    await backendApi.saveLayers(activeCanvasId, layerMap, layersDeleted);
    setLayersLoading(false);
  };

  const getLayers = async () => {
    if (!activeCanvasId || !open) return;
    setLayersLoading(true);
    const layerMap = await backendApi.getLayers(activeCanvasId);
    if (layerMap) {
      addNodesToMap(setNodes, setEdges, layerMap, setLayerMap);
    } else {
      highLightNodes(setNodes, setEdges, setLayerMap, categories);
    }
    setLayersLoading(false);
  };

  const isAllSelected = () => {
    return (
      Object.values(layerMap).filter((x: any) => x.checked).length ===
      Object.keys(layerMap).length
    );
  };

  const selectAll = () => {
    if (isAllSelected()) {
      Object.values(layerMap).forEach((x: any) => {
        x.checked = false;
      });
    } else {
      Object.values(layerMap).forEach((x: any) => {
        x.checked = true;
      });
    }
    console.log(layerMap);
    setLayerMap({ ...layerMap });
    updateColorsFromCategoryMap(setNodes, setEdges, layerMap);
  };

  useEffect(() => {
    getLayers();
  }, [activeCanvasId, open]);

  return (
    <Box
      sx={{
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        borderLeft:
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid rgba(0, 0, 0, 0.12)",
        visibility: "hidden",
        whiteSpace: "nowrap",
        ...(open && {
          width: 350,
          visibility: "visible",
          transition: theme.transitions.create(
            ["margin", "width", "visibility"],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }
          ),
        }),
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Box>
          <Box
            sx={{ pl: 2, pt: 1, pb: 1, display: "flex", flexDirection: "row" }}
          >
            <Typography variant="h6">Layers</Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Tooltip title="Save Layers">
              <IconButton onClick={saveLayers}>
                {layersLoading ? <CircularProgress size={20} /> : <Save />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Layer">
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => setCreateLayerOpen(true)}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Button
              startIcon={
                isAllSelected() ? <CheckBox /> : <CheckBoxOutlineBlank />
              }
              onClick={selectAll}
            >
              Select All
            </Button>
          </Box>
          <List sx={{ height: "calc(100vh - 210px)", overflow: "scroll" }}>
            {Object.keys(layerMap).map((x) => (
              <Box key={x}>
                <Droppable droppableId={x}>
                  {(provided, snapshot) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      <ListItem sx={{ mt: 1, mb: 1 }}>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: layerMap[x].color }}>
                            {x[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ whiteSpace: "wrap" }}>
                          {x}
                        </ListItemText>
                        {layerMap[x].nodes && layerMap[x].nodes.length ? (
                          <Checkbox
                            checked={layerMap[x].checked}
                            onChange={(e) => onCheckboxChange(e, x)}
                          />
                        ) : (
                          <IconButton
                            onClick={() => deleteLayer(x, layerMap[x])}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </ListItem>
                      <List disablePadding sx={{ pt: 0, minHeight: 20 }}>
                        {layerMap[x].nodes
                          .filter((x) => x.node)
                          .map((node, index) => (
                            <Draggable
                              key={node.node.id}
                              draggableId={node.node.id}
                              index={index}
                            >
                              {(provided) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{ pl: 5 }}
                                >
                                  <ListItemAvatar>
                                    <CompanyLogo
                                      product={{
                                        ...node.node.data,
                                        name: node.node.data.label,
                                      }}
                                      sx={{}}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    sx={{ whiteSpace: "wrap" }}
                                    primary={node.node.data.label}
                                    secondary={node.node.data.name}
                                  />
                                  <DragIndicator />
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                      </List>
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            ))}
          </List>
          <Box sx={{ p: 1 }}>
            <Button fullWidth variant="outlined">
              Create Project (
              {Object.values(layerMap).filter((x) => x.checked).length} Layers)
            </Button>
          </Box>
        </Box>
      </DragDropContext>
      <NewLayerDialog
        open={createLayerOpen}
        onClose={() => setCreateLayerOpen(false)}
        onConfirm={handleCreateNewLayer}
      />
    </Box>
  );
};
