import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const KeywordsInput = ({ onChange }) => {
  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const newKeyword = inputValue.trim();

      if (newKeyword && !keywords.includes(newKeyword)) {
        const updatedKeywords = [...keywords, newKeyword];
        setKeywords(updatedKeywords);
        onChange?.(updatedKeywords); // Notify parent component if needed
        setInputValue("");
      }
    }
  };

  const handleDelete = (keywordToDelete) => {
    const updatedKeywords = keywords.filter(
      (keyword) => keyword !== keywordToDelete
    );
    setKeywords(updatedKeywords);
    onChange?.(updatedKeywords); // Notify parent component if needed
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600 }}>
      <Typography variant="body2" color="text.secondary" mb={1}>
        Press Enter or add comma to add keywords
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Add keywords..."
        sx={{ mb: 2 }}
      />

      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {keywords.map((keyword) => (
          <Chip
            key={keyword}
            label={keyword}
            onDelete={() => handleDelete(keyword)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default KeywordsInput;
