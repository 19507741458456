import { Session } from "@supabase/supabase-js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { API_HOSTNAME } from "../../constants";

const flattenList = (object: any) => {
  const final = [];
  Object.keys(object).forEach((k) => {
    object[k].forEach((val) => {
      final.push(val);
    });
  });
  return final;
};

const findNode = (nodes, newNodes, productId) => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].data.product_id === productId) {
      return nodes[i].id;
    }
  }
  for (let i = 0; i < newNodes.length; i++) {
    if (newNodes[i].id === productId) {
      return newNodes[i].node_id;
    }
  }
  return null;
};

export const aiRequest = (
  objective: string,
  graphText: string,
  backendApi: any,
  session: Session,
  nodes: Array<any>,
  setAiMessages: Function,
  enqueueSnackbar: (string, obj) => void
): any => {
  const initialRequest = {
    graph: graphText,
    objective: objective,
  };

  const headers = {
    Authorization: `Bearer ${session.access_token}`,
  };

  var productsRes = [];
  var suggestion = {};
  return axios
    .post(`${API_HOSTNAME}/agents/suggestion`, initialRequest, {
      headers: headers,
    })
    .then((result) => {
      const jsonObject = result.data.suggestion;
      suggestion = jsonObject;
      if (jsonObject.reasoning) {
        setAiMessages((e) => [...e, jsonObject.reasoning]);
      }
      if (jsonObject.gaps) {
        setAiMessages((e) => [...e, jsonObject.productGaps]);
      }
      return backendApi.searchProductList(
        jsonObject.suggestedProducts.map((word) => word)
      );
    })
    .then((products) => {
      productsRes = flattenList(products);
      const data = {
        graph: graphText,
        objective: objective,
        suggestion: suggestion,
        products: productsRes,
      };
      return axios.post(`${API_HOSTNAME}/agents/suggestion/changes`, data, {
        headers: headers,
      });
    })
    .then((aiRes) => {
      const jsonObject = aiRes.data.changes;
      const newNodes = jsonObject.newNodes.map((node) => {
        return {
          ...productsRes.find((product) => product.id === node.id),
          node_id: uuidv4(),
        };
      });
      const newEdges = jsonObject.newEdges.map((edge) => {
        return {
          source: findNode(nodes, newNodes, edge.source),
          target: findNode(nodes, newNodes, edge.target),
        };
      });
      return { newNodes, newEdges };
    })
    .catch((error) => {
      console.error("Error:", error);
      return { newNodes: [], newEdges: [] };
    });
};
