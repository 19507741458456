import { Avatar } from "@mui/material";
import React from "react";

export default ({ product, sx = {} }: { product: any; sx: any }) => {
  const getProductInitials = () => {
    return product.name
      .split(" ")
      .map((name) => name[0].toUpperCase())
      .splice(0, 3)
      .join("");
  };
  return (
    <Avatar
      sx={{ ...sx, backgroundColor: "#fff" }}
      variant="rounded"
      alt={product.name}
      src={product.logo_url}
      slotProps={{
        img: {
          style: {
            objectFit: "contain",
          },
        },
      }}
    >
      {getProductInitials()}
    </Avatar>
  );
};
