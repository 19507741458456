import { Box } from "@mui/material";
import React from "react";

export default ({ mode }: { mode: string }) => {
  return (
    <Box sx={{ pt: 1 }}>
      <a>
        <img
          height="28"
          src={
            mode === "dark"
              ? require("./logos/logo_transparent_dark.png")
              : require("./logos/logo_transparent.png")
          }
        />
      </a>
    </Box>
  );
};
