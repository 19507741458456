import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CollaboratorsSearchField from "./CollaboratorSearchField";
import { Session, SupabaseClient } from "@supabase/supabase-js";

const CollaboratorsInviteDialog = ({
  open,
  onClose,
  onInvite,
  canvasId,
  mode,
  supabase,
  session,
  activeOrgId,
}: {
  open: boolean;
  onClose: () => void;
  onInvite: (organizationUser: any, role: string) => void;
  mode: string;
  activeOrgId: string;
  canvasId: string;
  session: Session;
  supabase: SupabaseClient;
}) => {
  const [organizationUser, setOrganizationUser] = useState<any>({});
  const [role, setRole] = useState("read");

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleOrganizationUserChange = (e, v) => {
    setOrganizationUser(v);
  };

  const handleInvite = (e) => {
    onInvite(organizationUser, role);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <CollaboratorsSearchField
          activeOrgId={activeOrgId}
          supabase={supabase}
          canvasId={canvasId}
          session={session}
          selectedOption={handleOrganizationUserChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select label="Role" value={role} onChange={handleRoleChange}>
            <MenuItem value="editor">Editor</MenuItem>
            <MenuItem value="read">Viewer</MenuItem>
            <MenuItem value="commentor">Commentor</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="outlined" color="primary" onClick={handleInvite}>
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollaboratorsInviteDialog;
