import React, { useState } from "react";

import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  debounce,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import BackendApi from "../BackendApi";
import CompanyLogo from "../company/CompanyLogo";

export default function CollaboratorsSearchField({
  activeOrgId,
  canvasId,
  session,
  supabase,
  selectedOption,
}: {
  activeOrgId: string;
  canvasId: string;
  session: Session;
  supabase: SupabaseClient;
  selectedOption: (e: any, option: any) => void;
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const backendApi = new BackendApi(supabase, session);

  const inputChanged = async (e: any) => {
    setLoading(true);
    const results = await backendApi.searchCanvasUsers(
      activeOrgId,
      session.user.id,
      e.target.value
    );
    setLoading(false);
    setOptions(
      results.map((x) => {
        return { ...x, title: x.name, label: x.name };
      })
    );
  };
  const searchDelayed = debounce(inputChanged, 700);

  return (
    <Autocomplete
      sx={{ mt: 1 }}
      id="search"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemAvatar>
            <CompanyLogo product={option} sx={{}} />
          </ListItemAvatar>
          {option.name}
          <ListItemText primary={option.name} secondary={option.email} />
        </ListItem>
      )}
      filterOptions={(options, state) => options}
      onInputChange={searchDelayed}
      onChange={selectedOption}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          placeholder="Enter name or email"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
