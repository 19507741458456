import { Session, SupabaseClient } from "@supabase/supabase-js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { API_HOSTNAME } from "./constants";

class BackendApi {
  supabase: SupabaseClient;
  session: Session;
  setSaving: (saving: boolean) => void;
  constructor(supabase: SupabaseClient, session: Session) {
    this.supabase = supabase;
    this.session = session;
  }

  async saveAttachment(canvasId: string, canvasNodeId: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `${API_HOSTNAME}/attachments/${canvasId}/${canvasNodeId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.session.access_token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  }

  async getAttachments(canvasId: string, canvasNodeId: string) {
    const res = await axios.get(
      `${API_HOSTNAME}/attachments/${canvasId}/${canvasNodeId}`,
      {
        headers: { Authorization: `Bearer ${this.session.access_token}` },
      }
    );
    return res.data;
  }

  async getLayers(canvasId) {
    const res = await axios.get(`${API_HOSTNAME}/canvas/${canvasId}/layers`, {
      headers: { Authorization: `Bearer ${this.session.access_token}` },
    });
    return res.data;
  }

  async saveLayers(canvasId: string, layerMap: any, layersDeleted) {
    const res = await axios.post(
      `${API_HOSTNAME}/canvas/${canvasId}/layers`,
      { layerMap, layersDeleted },
      {
        headers: { Authorization: `Bearer ${this.session.access_token}` },
      }
    );
    return res.data;
  }

  async getCategoriesTree(session: Session) {
    const res = await axios.get(`${API_HOSTNAME}/categories`, {
      headers: { Authorization: `Bearer ${session.access_token}` },
    });
    return res.data.categories;
  }

  async putProduct(product, activeOrgId, activeCompanyId) {
    return axios.post(
      `${API_HOSTNAME}/products`,
      { product, activeOrgId, activeCompanyId },
      {
        headers: { Authorization: `Bearer ${this.session.access_token}` },
      }
    );
  }

  async putCompany(company, activeOrgId) {
    return axios.post(
      `${API_HOSTNAME}/companies`,
      { company, activeOrgId },
      {
        headers: { Authorization: `Bearer ${this.session.access_token}` },
      }
    );
  }

  async getCompanies(userId: string, activeOrgId: string) {
    const { data, error } = await this.supabase
      .from("companies")
      .select("*, company_owners!inner(user_id, company_id, organization_id)")
      .eq("company_owners.organization_id", activeOrgId);
    if (error) {
      throw error;
    }
    return data;
  }

  async getCanvas(canvasId) {
    const res = await axios.get(`${API_HOSTNAME}/canvas/${canvasId}`, {
      headers: { Authorization: `Bearer ${this.session.access_token}` },
    });

    return res.data;
  }

  async saveCanvasNodes(nodes) {
    if (nodes.length === 0) {
      return { data: [], error: null };
    }
    const { data, error } = await this.supabase
      .from("canvas_nodes")
      .upsert(nodes, { onConflict: "id" });
    return { data, error };
  }

  async saveCanvasEdges(edges) {
    if (edges.length === 0) {
      return { data: [], error: null };
    }
    const { data, error } = await this.supabase
      .from("canvas_edges")
      .upsert(edges, { onConflict: "id" });
    return { data, error };
  }

  async deleteNodes(nodeIds: Array<string>) {
    const { data, error } = await this.supabase
      .from("canvas_nodes")
      .delete()
      .in("id", nodeIds);
    if (error) {
      throw error;
    }
    return data;
  }

  async deleteEdges(edgeIds: Array<string>) {
    const { data, error } = await this.supabase
      .from("canvas_edges")
      .delete()
      .in("id", edgeIds);
    if (error) {
      throw error;
    }
    return data;
  }

  async getCanvasList(userId: string, activeOrgId: string) {
    const { data, error } = await this.supabase
      .from("canvas_users")
      .select(
        `
        id,
        canvas_id,
        role,
        canvases!inner(id, organization_id, name, updated_date, created_by)
        `
      )
      .eq("user_id", userId)
      .eq("canvases.organization_id", activeOrgId);
    if (error) {
      throw error;
    }
    return data;
  }

  async getManagedProducts(
    userId: string,
    activeCompanyId: string,
    activeOrgId: string,
    page: number = 0,
    limit: number = 5
  ) {
    const { data, count, error } = await this.supabase
      .from("products")
      .select(
        `
        *,
        categories(*),
        companies!inner(id, name)`,
        {
          count: "exact",
        }
      )
      .eq("companies.id", activeCompanyId)
      .order("rating", { ascending: false })
      .range(page * limit, (page + 1) * limit)
      .limit(limit);
    if (error) {
      throw error;
    }
    return { data, count };
  }

  async getProductMetrics(
    userId: string,
    activeCompanyId: string,
    activeOrgId: string,
    page: number = 0,
    limit: number = 5
  ) {
    const { data, count, error } = await this.supabase
      .from("products")
      .select(
        `
        *,
        categories(*),
        companies!inner(id, name),
        canvas_nodes!inner(count)`,
        {
          count: "exact",
        }
      )
      .eq("companies.id", activeCompanyId)
      .order("rating", { ascending: false })
      .range(page * limit, (page + 1) * limit)
      .limit(limit);
    if (error) {
      throw error;
    }
    return { data, count };
  }

  async getMyProducts(userId: string, page: number = 0, limit: number = 5) {
    const res = await this.supabase
      .from("products")
      .select(
        `*,
        canvas_nodes!inner(product_id, deleted_date, created_date),
        categories(*)
    `,
        { count: "exact" }
      )
      .eq("canvas_nodes.created_by", userId)
      .is("canvas_nodes.deleted_date", null)
      .range(page * limit, (page + 1) * limit)
      .limit(limit);

    if (res.error) {
      throw res.error;
    }
    return res;
  }

  async getProduct(productId) {
    const { data, error } = await this.supabase
      .from("products")
      .select("*")
      .eq("id", productId)
      .single();
    if (error) {
      throw error;
    }
    return data;
  }

  async searchProductList(productList) {
    var res = {};
    for (const product of productList) {
      const { data, error } = await this.supabase
        .from("products")
        .select("*")
        .textSearch("name", product)
        .order("reviews", { ascending: false })
        .limit(2);
      if (!error) {
        res[product] = data;
      }
    }
    return res;
  }

  async getProducts(keyword, page: number = 0, limit: number = 5) {
    var res;
    if (keyword) {
      res = await this.supabase
        .from("products")
        .select(
          `*,
      categories(name)
      `,
          { count: "exact" }
        )
        .textSearch("name", keyword)
        .order("reviews", { ascending: false })
        .order("logo_url", { ascending: false, nullsFirst: false })
        .range(page * limit, (page + 1) * limit)
        .limit(limit);
    } else {
      res = await this.supabase
        .from("products")
        .select(
          `*,
      categories(name)
      `,
          { count: "exact" }
        )
        .order("reviews", { ascending: false })
        .order("logo_url", { ascending: false, nullsFirst: false })
        .range(page * limit, (page + 1) * limit)
        .limit(limit);
    }
    if (res.error) {
      throw res.error;
    }
    return res;
  }

  async getProductsByCategory(
    categoryId: string,
    page: number = 0,
    limit: number = 5
  ) {
    const res = await axios.get(`${API_HOSTNAME}/products`, {
      params: { categoryId, page, limit },
      headers: { Authorization: `Bearer ${this.session.access_token}` },
    });
    return res.data;
  }

  async searchProducts(keyword) {
    const res = await axios.get(`${API_HOSTNAME}/products`, {
      params: { keyword: keyword },
      headers: { Authorization: `Bearer ${this.session.access_token}` },
    });
    return res.data.items;
  }

  async getCategories() {
    const { data, error } = await this.supabase
      .from("categories")
      .select("*")
      .is("parent_id", null)
      .neq("name", "Other")
      .order("sort_order", { ascending: true })
      .limit(20);
    if (error) {
      throw error;
    }
    return data;
  }

  async getChildCategories(parentId) {
    const { data, error } = await this.supabase
      .from("categories")
      .select("*")
      .eq("parent_id", parentId)
      .order("name", { ascending: false });
    if (error) {
      throw error;
    }
    return data;
  }

  async getNotications(orgId, userId) {
    const { data, error } = await this.supabase
      .from("notifications")
      .select("*")
      .eq("organization_id", orgId)
      .eq("user_id", userId)
      .is("deleted_date", null)
      .order("created_date", { ascending: false });
    if (error) {
      throw error;
    }
    return data;
  }

  async createClaimRequest(orgId, userId, productId) {
    const { data, error } = await this.supabase
      .from("product_claim_requests")
      .insert({
        id: uuidv4(),
        organization_id: orgId,
        user_id: userId,
        product_id: productId,
      });
    if (error) {
      throw error;
    }
    return data;
  }

  async createCompanyClaimRequest(orgId, userId, companyId) {
    const { data, error } = await this.supabase
      .from("company_claim_requests")
      .insert({
        id: uuidv4(),
        organization_id: orgId,
        user_id: userId,
        company_id: companyId,
      });
    if (error) {
      throw error;
    }
    return data;
  }

  async getOrganizationUsers(orgId) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .select("*")
      .eq("organization_id", orgId);
    if (error) {
      throw error;
    }
    return data;
  }

  async joinOrganizations(orgs) {
    if (orgs.length === 0) {
      return { data: [], error: null };
    }
    orgs = orgs.map((x) => {
      return { ...x, accepted: true };
    });
    const { data, error } = await this.supabase
      .from("organization_users")
      .upsert(orgs, { onConflict: "id" });
    return { data, error };
  }

  async updateUserRole(orgId, userId, email, role) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .update({ role: role })
      .eq("organization_id", orgId)
      .or(`user_id.eq.${userId},email.eq.${email}`);
    if (error) {
      throw error;
    }
    return data;
  }

  async inviteUserToOrganization(orgId, email, role) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .insert({
        id: uuidv4(),
        organization_id: orgId,
        email: email,
        role: role,
      });
    if (error) {
      throw error;
    }
    return data;
  }

  async rejectOrganizationsInvite(id) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .delete()
      .eq("id", id)
      .single();
    if (error) {
      throw error;
    }
    return data;
  }

  async getPendingOrganizations(email) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .select(
        `
    id,
    organization_id,
    user_id,
    accepted,
    role,
    organizations (name)
  `
      )
      .eq("accepted", false)
      .ilike("email", email);

    if (error) {
      throw error;
    }
    return data;
  }

  async getOrganizations(session) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .select(
        `
      id,
      organization_id,
      user_id,
      accepted,
      role,
      organizations (name)
    `
      )
      .or(`user_id.eq.${session.user.id},email.eq.${session.user.email}`);

    if (error) {
      throw error;
    }
    return data;
  }

  async getCanvasUsers(canvasId) {
    const { data, error } = await this.supabase
      .from("canvas_users")
      .select("*,organization_users(name, email)")
      .eq("canvas_id", canvasId);

    if (error) {
      throw error;
    }
    return data;
  }

  async addCanvasUser(canvasId, userObj) {
    const { data, error } = await this.supabase.from("canvas_users").insert({
      id: uuidv4(),
      canvas_id: canvasId,
      user_id: userObj.user_id,
      organization_user_id: userObj.id,
      role: userObj.role,
    });
    if (error) {
      throw error;
    }
    return data;
  }

  async searchCanvasUsers(canvasId, userId, keyword) {
    const { data, error } = await this.supabase
      .from("organization_users")
      .select("*")
      .neq("user_id", userId)
      .or(`name.ilike.%${keyword}%,email.ilike.%${keyword}%`);
    if (error) {
      throw error;
    }
    return data;
  }
}

export default BackendApi;
