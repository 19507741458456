import { Button, CircularProgress } from "@mui/material";
import React from 'react';

export default ({
  text,
  loading,
  type,
}: {
  text: string;
  loading: boolean;
  type: "button" | "reset" | "submit";
}) => {
  return (
    <Button
      type={type}
      variant="outlined"
      color="primary"
      startIcon={loading ? <CircularProgress size={20} /> : <></>}
      disabled={loading}
      sx={{ mt: 3, mb: 2 }}
    >
      {text}
    </Button>
  );
};
