import { ChevronLeft, ChevronRight, DragIndicator } from "@mui/icons-material";
import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BackendApi from "../../BackendApi";
import CompanyLogo from "../../company/CompanyLogo";

export default ({
  session,
  supabase,
  onDragStart,
}: {
  session: Session;
  supabase: SupabaseClient;
  onDragStart: (event: any, productId: string, product: any) => void;
}) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const backendApi = new BackendApi(supabase, session);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 20;
  const observer = useRef();
  const [hasMore, setHasMore] = useState(true);
  const lastProductElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      // @ts-ignore
      if (observer.current) observer.current.disconnect();
      // @ts-ignore
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
          getProducts(breadCrumbs[breadCrumbs.length - 1].id, page + 1);
        }
      });
      // @ts-ignore
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getCategories = async () => {
    setLoading(true);
    const categories = await backendApi.getCategories();
    setLoading(false);
    setCategories(categories);
    setProducts([]);
  };

  const getSubCategories = async (category: any) => {
    if (category.parent_id) {
      setBreadcrumbs([...breadCrumbs, category]);
      getProducts(category.id);
    } else {
      setLoading(true);
      setBreadcrumbs([...breadCrumbs, category]);
      const subCategories = await backendApi.getChildCategories(category.id);
      setLoading(false);
      setProducts([]);
      setCategories(subCategories);
    }
  };

  const getProducts = async (categoryId: string, page: number = 1) => {
    setLoading(true);
    const { items, total } = await backendApi.getProductsByCategory(
      categoryId,
      page,
      limit
    );
    setCategories([]);
    setLoading(false);
    setProducts((prev: any[]) => [...prev, ...items]);
    setHasMore(items.length === limit);
    setPages(Math.ceil(total / limit));
  };

  const backClick = async () => {
    const lastCategory = breadCrumbs.pop();
    if (lastCategory.parent_id) {
      setLoading(true);
      const subCategories = await backendApi.getChildCategories(
        breadCrumbs[0].id
      );
      setLoading(false);
      setPages(0);
      setProducts([]);
      setCategories(subCategories);
    } else {
      await getCategories();
    }
    setBreadcrumbs(breadCrumbs);
  };

  const setPageEvent = (event: any, value: number) => {
    getProducts(breadCrumbs[breadCrumbs.length - 1].id, value);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <React.Fragment>
      {breadCrumbs.length > 0 && (
        <Button sx={{ mt: 1 }} onClick={backClick} startIcon={<ChevronLeft />}>
          Back
        </Button>
      )}
      <Box sx={{ height: "calc(100vh - 270px)", overflow: "scroll" }}>
        <List>
          {categories.map((category) => (
            <ListItemButton
              key={category.id}
              onClick={() => getSubCategories(category)}
            >
              <ListItemText
                primary={category.name}
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              />
              <ChevronRight />
            </ListItemButton>
          ))}
          {products.map((product, idx) => (
            <ListItem
              key={product.id}
              onDragStart={(event) => onDragStart(event, product.id, product)}
              draggable
              sx={{ cursor: "move" }}
              ref={idx === products.length - 1 ? lastProductElementRef : null}
            >
              <ListItemAvatar>
                <CompanyLogo product={product} sx={{}} />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              />
              <DragIndicator sx={{ ml: 1 }} />
            </ListItem>
          ))}
        </List>
      </Box>
      {loading ? (
        <LinearProgress sx={{ mt: 1 }} color="inherit" />
      ) : (
        <Box style={{ height: 10 }}></Box>
      )}
    </React.Fragment>
  );
};
