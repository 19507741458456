import { Session } from "@supabase/supabase-js";

export default function caputre(name: string, data: any) {
  // @ts-ignore
  if (window.posthog) {
    // @ts-ignore
    window.posthog.capture(name, data);
  }
}

export function identify(session: Session) {
  try {
    // @ts-ignore
    if (window.posthog && session && session.user) {
      var firstName = "";
      var lastName = "";
      var nameParts = session.user.user_metadata.name.split(" ");
      if (nameParts) {
        if (nameParts.length > 0) {
          firstName = nameParts[0];
        }
        if (nameParts.length > 1) {
          lastName = nameParts[1];
        }
      }
      var distictId = "";
      try {
        // @ts-ignore
        distictId = window.posthog.get_distinct_id();
      } catch {
        distictId = "";
      }
      if (distictId === session.user.email) {
        return;
      }

      // @ts-ignore
      window.posthog.identify(session.user.email, {
        email: session.user.email,
        displayName: session.user.user_metadata.name,
        firstName: firstName,
        lastName: lastName,
        uid: session.user.id,
      });
    }
  } catch (error) {}
}
