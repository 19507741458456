import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import CompanyLogo from "./CompanyLogo";

export default ({
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProducts = async (page) => {
    if (!session || !session.user || !activeOrgId || !activeCompanyId) return;
    setLoading(true);
    const { data, count } = await backendApi.getProductMetrics(
      session.user.id,
      activeCompanyId,
      activeOrgId,
      page,
      25
    );
    setProducts(updateProductsMetrics(data));
    setTotal(count);
    setLoading(false);
  };

  const updateProductsMetrics = (products: Array<any>) => {
    products.forEach((product) => {
      const count = product.canvas_nodes[0].count;
      const addedToCanvas =
        count === 0 ? parseInt(`${Math.random() * 1000}`) : count;
      const leads = parseInt(`${addedToCanvas * 0.67}`);
      const impressions = parseInt(`${Math.random() * 10000}`);
      const organicImpressions = parseInt(`${impressions * 0.66}`);
      const paidImpressions = parseInt(`${impressions * 0.44}`);
      product.addedToCanvas = addedToCanvas;
      product.leads = leads;
      product.impressions = impressions;
      product.organicImpressions = organicImpressions;
      product.paidImpressions = paidImpressions;
    });
    return products;
  };

  useEffect(() => {
    getProducts(page);
  }, [activeOrgId, activeCompanyId]);

  const getAddedToCanvas = (count: number) => {
    return count === 0 ? parseInt(`${Math.random() * 1000}`) : count;
  };

  return (
    <Box>
      <LinearProgress sx={{ display: loading ? "block" : "none", mt: 1 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Added To Canvas</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Impressions</TableCell>
              <TableCell>Organic Impressions</TableCell>
              <TableCell>Paid Impressions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.name}>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  <CompanyLogo product={product} sx={{}} />
                </TableCell>
                <TableCell>
                  {product.categories.map((x) => x.name).join(",  ") ||
                    "General"}
                </TableCell>
                <TableCell>{product.addedToCanvas}</TableCell>
                <TableCell>{product.leads}</TableCell>
                <TableCell>{product.impressions}</TableCell>
                <TableCell>{product.organicImpressions}</TableCell>
                <TableCell>{product.paidImpressions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
