import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React from "react";
import BackendApi from "../BackendApi";
import CompanyProfile from "./CompanyProfile";

export default ({
  open,
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
  onClose,
  onSave,
}: {
  open: boolean;
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
  onClose: () => void;
  onSave: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Company</DialogTitle>
      <DialogContent>
        <CompanyProfile
          session={session}
          backendApi={backendApi}
          activeOrgId={activeOrgId}
          activeCompany={{}}
          formOnly
          onSave={onSave}
          fileId="company-file-dialog-select"
        />
      </DialogContent>
    </Dialog>
  );
};
