// src/pages/Campaigns.jsx
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useState } from "react";
import BackendApi from "../../BackendApi";
import CategorySelect from "../../components/CategorySelect";
import KeywordsInput from "../../components/KeywordsInput";

export default ({
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [campaigns, setCampaigns] = useState([
    {
      id: 1,
      name: "Summer Tech Sale",
      budget: 5000,
      status: "active",
      startDate: "2024-06-01",
      endDate: "2024-08-31",
      cpc: 1.25,
      cpm: 8.5,
      targetAudience: ["Technology", "Small Businesses", "Startups"],
      performance: {
        clicks: 3800,
        impressions: 150000,
        spend: 4200,
        revenue: 12500,
        ctrTrend: 2.8,
        cpcTrend: -0.5,
        cpmTrend: -0.3,
        roiTrend: 3.2,
      },
    },
    {
      id: 2,
      name: "Enterprise Cloud Solutions",
      budget: 15000,
      status: "scheduled",
      startDate: "2024-09-15",
      endDate: "2024-12-15",
      cpc: 2.75,
      cpm: 12.0,
      targetAudience: ["Enterprise", "Technology", "Professional Services"],
      performance: {
        clicks: 5200,
        impressions: 280000,
        spend: 12800,
        revenue: 45000,
        ctrTrend: 1.5,
        cpcTrend: -0.8,
        cpmTrend: -1.2,
        roiTrend: 4.5,
      },
    },
    {
      id: 3,
      name: "Healthcare AI Integration",
      budget: 8500,
      status: "draft",
      startDate: "2024-07-01",
      endDate: "2024-10-31",
      cpc: 1.85,
      cpm: 9.75,
      targetAudience: ["Healthcare", "Technology", "Enterprise"],
      performance: {
        clicks: 4100,
        impressions: 195000,
        spend: 7200,
        revenue: 28000,
        ctrTrend: 3.2,
        cpcTrend: -0.4,
        cpmTrend: -0.6,
        roiTrend: 2.8,
      },
    },
    {
      id: 4,
      name: "FinTech Innovation Summit",
      budget: 12000,
      status: "pending_review",
      startDate: "2024-08-15",
      endDate: "2024-11-30",
      cpc: 2.25,
      cpm: 10.5,
      targetAudience: [
        "Finance",
        "Technology",
        "Startups",
        "Professional Services",
      ],
      performance: {
        clicks: 4800,
        impressions: 220000,
        spend: 9600,
        revenue: 35000,
        ctrTrend: 2.1,
        cpcTrend: -0.3,
        cpmTrend: -0.9,
        roiTrend: 3.7,
      },
    },
  ]);
  const [newCampaign, setNewCampaign] = useState({
    name: "",
    budget: "",
    startDate: "",
    endDate: "",
  });
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const handleCreateCampaign = () => {
    setCampaigns([
      ...campaigns,
      {
        id: campaigns.length + 1,
        ...newCampaign,
        status: "active",
      },
    ]);
    setOpenDialog(false);
    setNewCampaign({ name: "", budget: "", startDate: "", endDate: "" });
  };

  const handleStatusChange = (id, newStatus) => {
    setCampaigns(
      campaigns.map((campaign) =>
        campaign.id === id ? { ...campaign, status: newStatus } : campaign
      )
    );
  };

  const handleDelete = (id) => {
    setCampaigns(campaigns.filter((campaign) => campaign.id !== id));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6">Marketing Campaigns</Typography>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create Campaign
        </Button>
      </Box>

      <Stack spacing={2}>
        {campaigns.map((campaign) => (
          <Card key={campaign.id}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h6">{campaign.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Budget: ${campaign.budget}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${campaign.startDate} - ${campaign.endDate}`}
                  </Typography>
                  <Chip
                    label={campaign.status}
                    color={campaign.status === "active" ? "success" : "default"}
                    size="small"
                    variant="outlined"
                    sx={{ mt: 1 }}
                  />
                </Box>
                <Box>
                  {campaign.status === "active" ? (
                    <IconButton
                      onClick={() => handleStatusChange(campaign.id, "paused")}
                      color="warning"
                    >
                      <PauseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleStatusChange(campaign.id, "active")}
                      color="success"
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => handleDelete(campaign.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Campaign</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label="Campaign Name"
              fullWidth
              value={newCampaign.name}
              onChange={(e) =>
                setNewCampaign({ ...newCampaign, name: e.target.value })
              }
            />
            <TextField
              label="Budget"
              type="number"
              fullWidth
              value={newCampaign.budget}
              onChange={(e) =>
                setNewCampaign({ ...newCampaign, budget: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <Box component="span" sx={{ mr: 1 }}>
                    $
                  </Box>
                ),
              }}
            />
            <CategorySelect
              session={session}
              value={categories}
              onChange={(e) => setCategories(e.target.value)}
            />
            <KeywordsInput onChange={(v) => setKeywords(v)} />
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={newCampaign.startDate}
                onChange={(e) =>
                  setNewCampaign({ ...newCampaign, startDate: e.target.value })
                }
              />
              <TextField
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={newCampaign.endDate}
                onChange={(e) =>
                  setNewCampaign({ ...newCampaign, endDate: e.target.value })
                }
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            variant="outlined"
            onClick={handleCreateCampaign}
            disabled={
              !newCampaign.name ||
              !newCampaign.budget ||
              !newCampaign.startDate ||
              !newCampaign.endDate
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
