import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const consultants = [
  {
    id: 5,
    name: "Lucy Heart",
    image: "https://i.pravatar.cc/300?img=5",
    expertise: "Healthcare IT, Compliance",
    rating: 3.9,
  },
  {
    id: 6,
    name: "Gary Young",
    image: "https://i.pravatar.cc/300?img=6",
    expertise: "Cybersecurity, Network Defense",
    rating: 4.6,
  },
  {
    id: 7,
    name: "Samantha Bloom",
    image: "https://i.pravatar.cc/300?img=7",
    expertise: "Data Analytics, BI Tools",
    rating: 4.0,
  },
  {
    id: 8,
    name: "Chris Rock",
    image: "https://i.pravatar.cc/300?img=8",
    expertise: "SaaS Products, Market Strategy",
    rating: 4.5,
  },
  {
    id: 9,
    name: "Bella Swan",
    image: "https://i.pravatar.cc/300?img=9",
    expertise: "E-commerce, B2C Models",
    rating: 4.8,
  },
  {
    id: 10,
    name: "Harry White",
    image: "https://i.pravatar.cc/300?img=10",
    expertise: "Machine Learning, AI",
    rating: 4.2,
  },
  {
    id: 11,
    name: "Tom Redding",
    image: "https://i.pravatar.cc/300?img=11",
    expertise: "Fintech, Blockchain",
    rating: 4.9,
  },
  {
    id: 12,
    name: "Sara Parker",
    image: "https://i.pravatar.cc/300?img=12",
    expertise: "UX/UI Design, Frontend Development",
    rating: 4.3,
  },
  {
    id: 13,
    name: "Robert Fox",
    image: "https://i.pravatar.cc/300?img=13",
    expertise: "Mobile App Development, React Native",
    rating: 4.6,
  },
  {
    id: 14,
    name: "Olivia Kane",
    image: "https://i.pravatar.cc/300?img=14",
    expertise: "Social Media Marketing, SEO",
    rating: 4.7,
  },
  {
    id: 15,
    name: "Mark Neal",
    image: "https://i.pravatar.cc/300?img=15",
    expertise: "Digital Advertising, Google Ads",
    rating: 4.5,
  },
  {
    id: 16,
    name: "Nina Morris",
    image: "https://i.pravatar.cc/300?img=16",
    expertise: "Real Estate Technologies, GIS",
    rating: 4.4,
  },
  {
    id: 17,
    name: "Dave Wilson",
    image: "https://i.pravatar.cc/300?img=17",
    expertise: "E-learning, Educational Technologies",
    rating: 4.1,
  },
  {
    id: 18,
    name: "Ella Fitzgerald",
    image: "https://i.pravatar.cc/300?img=18",
    expertise: "Music Technologies, Production Software",
    rating: 4.8,
  },
  {
    id: 19,
    name: "Sophie Turner",
    image: "https://i.pravatar.cc/300?img=19",
    expertise: "Entertainment Industry, Streaming Technologies",
    rating: 4.2,
  },
  {
    id: 20,
    name: "Liam Neeson",
    image: "https://i.pravatar.cc/300?img=20",
    expertise: "Logistics, Supply Chain Management",
    rating: 4.6,
  },
  {
    id: 21,
    name: "Grace Lin",
    image: "https://i.pravatar.cc/300?img=21",
    expertise: "Game Development, Unity & Unreal Engines",
    rating: 4.3,
  },
  {
    id: 22,
    name: "Frank Murphy",
    image: "https://i.pravatar.cc/300?img=22",
    expertise: "Pharmaceuticals, R&D",
    rating: 4.7,
  },
  {
    id: 23,
    name: "Emma Stone",
    image: "https://i.pravatar.cc/300?img=23",
    expertise: "Legal Tech, Compliance Software",
    rating: 4.5,
  },
  {
    id: 24,
    name: "Ryan Gosling",
    image: "https://i.pravatar.cc/300?img=24",
    expertise: "Automotive Industry, Manufacturing Technologies",
    rating: 4.6,
  },
  {
    id: 25,
    name: "Natalie Portman",
    image: "https://i.pravatar.cc/300?img=25",
    expertise: "Healthcare Management, Hospital Software",
    rating: 4.8,
  },
];

export default () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConsultants = consultants.filter(
    (consultant) =>
      consultant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      consultant.expertise.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <TextField
        fullWidth
        label="Search Consultants"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={3}>
        {filteredConsultants.map((consultant) => (
          <Grid item key={consultant.id} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={consultant.image}
                  alt={consultant.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {consultant.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Expertise: {consultant.expertise}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Rating: {consultant.rating} / 5
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
