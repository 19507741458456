import { Box, Typography, useTheme } from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React from "react";
import CategoryListBreadcrumb from "../products/CategoryListBreadcrumb";
import SearchBar from "./SearchBar";

export default ({
  open,
  session,
  supabase,
  selectedSearchOption,
  onDragStart,
}: {
  open: boolean;
  session: Session;
  supabase: SupabaseClient;
  selectedSearchOption: (event: any, product: any) => void;
  onDragStart: (event: any, nodeType: any, product: any) => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 0,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        borderRight:
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid rgba(0, 0, 0, 0.12)",
        visibility: "hidden",
        whiteSpace: "nowrap",
        ...(open && {
          p: 1,
          width: 300,
          visibility: "visible",
          transition: theme.transitions.create(
            ["margin", "width", "visibility"],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }
          ),
        }),
      }}
    >
      <SearchBar
        session={session}
        supabase={supabase}
        selectedOption={selectedSearchOption}
        onDragStart={onDragStart}
      />

      <CategoryListBreadcrumb
        session={session}
        supabase={supabase}
        onDragStart={onDragStart}
      />
      <Typography sx={{ mt: 2, whiteSpace: "normal" }}>
        You can drag these nodes to the pane on the right.
      </Typography>
    </Box>
  );
};
