import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React from "react";
import BackendApi from "../BackendApi";

const Profile = ({
  mode,
  supabase,
  session,
  activeOrgId,
  backendApi,
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  backendApi: BackendApi;
}) => {
  return (
    <Box sx={{ maxWidth: 600, p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Notification Settings
      </Typography>

      <FormControlLabel
        control={<Switch />}
        label="Enable Email Notifications"
      />

      <FormControlLabel
        control={<Switch />}
        label="Enable Push Notifications"
      />

      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
      >
        <Typography variant="h6" gutterBottom>
          Reset Password
        </Typography>
        <TextField fullWidth label="Current Password" />
        <TextField fullWidth label="New Password" />
        <Button fullWidth variant="outlined">
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default Profile;
