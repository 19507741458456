import { CssBaseline, PaletteMode, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createClient } from "@supabase/supabase-js";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./src/Dashboard";
import Login from "./src/Login";

const supabase = createClient(
  "https://kupcaenotqbcsbthsfiz.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt1cGNhZW5vdHFiY3NidGhzZml6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk5MzMzODEsImV4cCI6MjAyNTUwOTM4MX0.wQrnedn1SLLZlkGU2hiNxjVgk5j6BYzVbYihrmu2T6o"
);

const getTheme = (mode: PaletteMode) => {
  const primaryColor = mode === "dark" ? "#A3F7BF" : "#29A19C";
  const secondaryColor = mode === "dark" ? "#29A19C" : "#A3F7BF";
  const contrastColor = mode === "dark" ? "#fff" : "#000";
  const theme = {
    palette: {
      mode: mode,
      background: {
        default: mode === "dark" ? "#191919" : "#f7f7f7",
      },
      primary: {
        main: primaryColor,
        contrastText: contrastColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: mode === "dark" ? "#121212" : "#fff",
          },
        },
      },
    },
  };
  return createTheme(theme);
};

export default function App() {
  const [mode, setMode] = useState(
    window.localStorage.getItem("mode") || "system"
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  var theme = null;
  if (mode === "system") {
    theme = getTheme("light");
    setMode("light");
  } else {
    theme = getTheme(mode as PaletteMode);
  }

  const setThemeMode = (mode: string) => {
    window.localStorage.setItem("mode", mode);
    setMode(mode);
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Login supabase={supabase} mode={mode} />}
            />
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  supabase={supabase}
                  mode={mode}
                  setMode={setThemeMode}
                />
              }
            />
            <Route
              path="/dashboard/:topPage"
              element={
                <Dashboard
                  supabase={supabase}
                  mode={mode}
                  setMode={setThemeMode}
                />
              }
            />
            <Route
              path="/dashboard/:topPage/:secondPage"
              element={
                <Dashboard
                  supabase={supabase}
                  mode={mode}
                  setMode={setThemeMode}
                />
              }
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
