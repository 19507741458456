import { Box, List, ListItemButton, useTheme } from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useState } from "react";
import BackendApi from "../BackendApi";
import ProfileEdit from "./ProfileEdit";
import Projects from "./Projects";
import TalentMarketplace from "./TalentMarketplace";

export default ({
  mode,
  supabase,
  session,
  activeOrgId,
  backendApi,
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  backendApi: BackendApi;
}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuItem, setMenuItem] = useState("projects");
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: 0,
          borderRight:
            theme.palette.mode === "dark"
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : "1px solid rgba(0, 0, 0, 0.12)",
          visibility: "hidden",
          whiteSpace: "nowrap",
          ...(menuOpen && {
            width: 250,
            visibility: "visible",
            transition: theme.transitions.create(
              ["margin", "width", "visibility"],
              {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }
            ),
          }),
        }}
      >
        <Box sx={{ p: 1 }}>
          <List>
            <ListItemButton
              selected={menuItem === "projects"}
              disabled={menuItem === "projects"}
              onClick={() => setMenuItem("projects")}
            >
              Projects
            </ListItemButton>
            <ListItemButton
              selected={menuItem === "talent"}
              disabled={menuItem === "talent"}
              onClick={() => setMenuItem("talent")}
            >
              Talent Marketplace
            </ListItemButton>
            <ListItemButton
              selected={menuItem === "profile"}
              disabled={menuItem === "profile"}
              onClick={() => setMenuItem("profile")}
            >
              Profile
            </ListItemButton>
          </List>
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 67px)", width: "100%" }}>
        <Box sx={{ display: menuItem === "projects" ? "block" : "none" }}>
          <Projects
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
          />
        </Box>
        <Box sx={{ display: menuItem === "talent" ? "block" : "none" }}>
          <TalentMarketplace />
        </Box>
        <Box sx={{ display: menuItem === "profile" ? "block" : "none" }}>
          <ProfileEdit
            session={session}
            backendApi={backendApi}
            activeOrgId={activeOrgId}
          />
        </Box>
      </Box>
    </Box>
  );
};
