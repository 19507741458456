import { ArrowForward, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CompanyLogo from "../../company/CompanyLogo";
import ProductAttachments from "../../products/ProductAttachments";
import ProductRating from "../../products/ProductRating";

export default ({
  selectedProduct,
  nodeSelectedOpen,
  productTab,
  setProductTab,
  setSelectedProduct,
  selectedNode,
  backendApi,
  activeCanvasId,
  session,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 0,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        minWidth: 0,
        borderLeft:
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid rgba(0, 0, 0, 0.12)",
        visibility: "hidden",
        whiteSpace: "nowrap",
        ...(Object.keys(selectedProduct).length > 0 &&
          nodeSelectedOpen && {
            p: 1,
            width: 450,
            minWidth: 450,
            visibility: "visible",
            transition: theme.transitions.create(
              ["margin", "width", "visibility"],
              {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }
            ),
          }),
      }}
    >
      {Object.keys(selectedProduct).length > 0 && (
        <Box>
          <IconButton onClick={() => setSelectedProduct({})}>
            <ArrowForward />
          </IconButton>
          <Box sx={{ mt: 2, mb: 2 }}>
            <CompanyLogo
              product={selectedProduct}
              sx={{ height: 80, width: 80 }}
            />
          </Box>
          <Typography sx={{ whiteSpace: "wrap" }}>
            {selectedProduct.name}{" "}
            <Tooltip sx={{ ml: 1, pt: 1 }} title={selectedProduct.description}>
              <InfoOutlined />
            </Tooltip>
          </Typography>
          <Box sx={{ mt: 1 }}>
            <ProductRating
              rating={selectedProduct.rating}
              reviews={selectedProduct.reviews}
            />
          </Box>
          <Box
            sx={{ mt: 1, height: "calc(100vh - 400px)", overflow: "scroll" }}
          >
            <Tabs value={productTab} onChange={(e: any, v) => setProductTab(v)}>
              <Tab value="description" label="Description" />
              <Tab value="attachments" label="Attachments" />
            </Tabs>
            <Box
              sx={{
                whiteSpace: "wrap",
              }}
            >
              {productTab === "description" && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={selectedNode.data.name}
                    onChange={(e) =>
                      selectedNode.data.onSave(selectedNode.id, {
                        ...selectedNode.data,
                        name: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label="Description"
                    value={selectedNode.data.description}
                    onChange={(e) =>
                      selectedNode.data.onSave(selectedNode.id, {
                        ...selectedNode.data,
                        description: e.target.value,
                      })
                    }
                    multiline
                    rows={4}
                  />
                </Box>
              )}
              {productTab === "attachments" && (
                <ProductAttachments
                  session={session}
                  canvasId={activeCanvasId}
                  canvasNodeId={selectedNode.id}
                  backendApi={backendApi}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
