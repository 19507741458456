import { Notifications } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Container,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import ReactFlow from "reactflow";

export default () => {
  const projects = [
    { id: 1, name: "Project A", status: "In Progress" },
    { id: 2, name: "Project B", status: "Completed" },
    { id: 3, name: "Project C", status: "Pending" },
  ];

  const stakeholders = [
    { id: 1, name: "John Doe", role: "Manager" },
    { id: 2, name: "Jane Smith", role: "Developer" },
    { id: 3, name: "Mike Johnson", role: "Designer" },
  ];

  const elements = [
    { id: "1", data: { label: "Project A" }, position: { x: 100, y: 100 } },
    { id: "2", data: { label: "Project B" }, position: { x: 300, y: 100 } },
    { id: "3", data: { label: "Project C" }, position: { x: 500, y: 100 } },
  ];

  const notifications = [
    {
      id: 1,
      message: "John Doe made changes to Project A",
      timestamp: "2 hours ago",
    },
    {
      id: 2,
      message: "Jane Smith commented on Project B",
      timestamp: "4 hours ago",
    },
    {
      id: 3,
      message: "Mike Johnson added a new task to Project C",
      timestamp: "1 day ago",
    },
  ];

  const collaborators = [
    { id: 1, name: "John Doe", avatar: require("./img/avatar1.jpg") },
    {
      id: 2,
      name: "Jane Smith",
      avatar: require("./img/avatar3.jpg"),
    },
    {
      id: 3,
      name: "Mike Johnson",
      avatar: require("./img/avatar2.jpg"),
    },
  ];

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Projects
              </Typography>
              {projects.map((project) => (
                <Typography key={project.id}>
                  {project.name} - {project.status}
                </Typography>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Stakeholders
              </Typography>
              {stakeholders.map((stakeholder) => (
                <Typography key={stakeholder.id}>
                  {stakeholder.name} - {stakeholder.role}
                </Typography>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Notifications
              </Typography>
              {notifications.map((notification) => (
                <Box
                  key={notification.id}
                  display="flex"
                  alignItems="center"
                  mb={2}
                >
                  <Notifications color="primary" />
                  <Box ml={2}>
                    <Typography variant="body1">
                      {notification.message}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {notification.timestamp}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ height: 263 }}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Collaborators
              </Typography>
              {collaborators.map((collaborator) => (
                <Box
                  key={collaborator.id}
                  display="flex"
                  alignItems="center"
                  mb={2}
                >
                  <Avatar alt={collaborator.name} src={collaborator.avatar} />
                  <Box ml={2}>
                    <Typography variant="body1">{collaborator.name}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Tech Roadmap
              </Typography>
              <div style={{ height: "400px" }}>
                <ReactFlow nodes={elements} />
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
