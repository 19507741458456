import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import CompanyLogo from "../company/CompanyLogo";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { PRODUCT_NAME } from "../constants";
import ProductRating from "../products/ProductRating";

const ProductDetailDialog = ({
  open,
  onClose,
  product,
  backendApi,
  activeOrgId,
  session,
}) => {
  const [claimOpen, setClaimOpen] = useState(false);
  const [claimedProduct, setClaimedProduct] = useState<any>({});

  const onConfirmClaim = async () => {
    await backendApi.createClaimRequest(
      activeOrgId,
      session.user.id,
      product.id
    );
    setClaimOpen(false);
  };

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={claimOpen}
        onClose={() => setClaimOpen(false)}
        onConfirm={onConfirmClaim}
        title={`Claim Product ${product.name}?`}
        message={`Next steps:

- A ${PRODUCT_NAME} representative will be reaching out to complete the claim process.
- You will be granted admin status for your profile which will enable you to update your information.
- Connect to real users who are researching you and get the most value out of ${PRODUCT_NAME}!
        `}
        confirmText={"confirm"}
        cancelText={"cancel"}
      />
      <Dialog open={open} onClose={onClose} sx={{ minWidth: 300 }}>
        <Box sx={{ textAlign: "right", mt: 1, mr: 1.5 }}>
          <Link onClick={() => setClaimOpen(true)} sx={{ cursor: "pointer" }}>
            Claim Product
          </Link>
        </Box>
        <Box sx={{ mt: 3, ml: 3, mb: 2 }}>
          <CompanyLogo product={product} sx={{ height: 80, width: 80 }} />
        </Box>
        <DialogTitle>{product.name}</DialogTitle>
        <Box sx={{ ml: 3 }}>
          <ProductRating rating={product.rating} reviews={product.reviews} />
        </Box>
        <DialogContent>
          <DialogContentText>{product.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            component={Link}
            target="_blank"
            rel="noopener"
            href={`https://g2.com/products/${product.value}`}
          >
            G2 Profile
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductDetailDialog;
