import { MoreVert } from "@mui/icons-material";
import {
  Card,
  Fade,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import CompanyLogo from "../company/CompanyLogo";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(data.name || "");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onDelete = (e) => {
      e.stopPropagation();
      data.onDelete(id);
    };

    const onView = (e) => {
      e.stopPropagation();
      handleClose();
      data.onView(id, data);
    };

    const onEditing = (e) => {
      e.stopPropagation();
      setEditing(!editing);
    };

    const doneEditing = (e) => {
      e.stopPropagation();
      setEditing(false);
      data.onSave(id, { ...data, name });
    };

    return (
      <Card
        sx={{
          p: 1,
          display: "flex",
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <CompanyLogo product={{ ...data, name: data.label }} sx={{}} />
          </ListItemAvatar>
          <ListItemText
            sx={{ display: "block", maxWidth: 200 }}
            primary={data.label}
            secondary={data.name}
            primaryTypographyProps={{
              style: {
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
          <IconButton sx={{ ml: 2 }} onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={onView}>Details</MenuItem>
            <MenuItem onClick={onDelete}>Delete</MenuItem>
          </Menu>
        </ListItem>
        <Handle
          position={Position.Left}
          isConnectable={isConnectable}
          id="a"
          type="target"
        />
        <Handle
          position={Position.Right}
          isConnectable={isConnectable}
          id="d"
          type="source"
        />
      </Card>
    );
  }
);
