import { Add, Check } from "@mui/icons-material";
import {
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";

export default ({
  canvas,
  canvasList,
  open,
  setCanvas,
  createNewCanvas,
  canvasNameChanged,
}: {
  canvas: any;
  canvasList: Array<any>;
  open: boolean;
  setCanvas: (canvas: any) => void;
  createNewCanvas: () => void;
  canvasNameChanged: (event: any) => void;
}) => {
  const theme = useTheme();

  const CanvasListItem = ({ can, canvas }: { can: any; canvas: any }) => {
    if (can.canvas_id !== canvas.canvas_id) {
      return (
        <ListItemButton
          selected={can.canvas_id === canvas.canvas_id}
          onClick={() => setCanvas(can)}
        >
          <ListItemText sx={{ textOverflow: "hidden", overflow: "hidden" }}>
            {can.canvases.name}
          </ListItemText>
          {can.canvas_id === canvas.canvas_id && <Check />}
        </ListItemButton>
      );
    } else {
      return (
        <ListItem>
          <InputBase
            value={canvas.canvases.name}
            onChange={canvasNameChanged}
            fullWidth
          />
          <Check />
        </ListItem>
      );
    }
  };

  return (
    <List
      sx={{
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        borderRight:
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid rgba(0, 0, 0, 0.12)",
        visibility: "hidden",
        whiteSpace: "nowrap",
        ...(open && {
          width: 280,
          visibility: "visible",
          transition: theme.transitions.create(
            ["margin", "width", "visibility"],
            {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }
          ),
        }),
      }}
    >
      <ListItemButton onClick={createNewCanvas}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText>New Canvas</ListItemText>
      </ListItemButton>
      <Divider sx={{ mt: 1, mb: 1 }} />
      {canvasList.map((can, index) => (
        <CanvasListItem key={index} can={can} canvas={canvas} />
      ))}
    </List>
  );
};
