import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import { SupabaseClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";

const companySizeOptions = [
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1000+", label: "1000+" },
];

const CreateOrganizationDialog = ({
  open,
  onClose,
  onSubmit,
  session,
  supabase,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (orgId: string) => void;
  session: any;
  supabase: SupabaseClient;
}) => {
  const [orgName, setOrgName] = useState("");
  const [website, setWebsite] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const orgId = uuidv4();
    await supabase
      .from("organizations")
      .insert({
        id: orgId,
        name: orgName,
        website,
        company_size: companySize,
      })
      .then((res) => {
        if (res.status === 201) {
          supabase
            .from("organization_users")
            .insert({
              id: uuidv4(),
              email: session.user.email,
              name: session.user.user_metadata.name,
              photo_url: session.user.user_metadata.avatar_url,
              accepted: true,
              user_id: session.user.id,
              organization_id: orgId,
              role: "owner",
            })
            .then((res) => {
              setOrgName("");
              setWebsite("");
              setCompanySize("");
              onSubmit(orgId);
            });
        }
      });
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Organization</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new organization to collaborate with your team and manage
          your projects efficiently. Please provide the following details to get
          started:
        </DialogContentText>
        <TextField
          sx={{ mt: 3, mb: 1 }}
          autoFocus
          label="Organization Name"
          type="text"
          fullWidth
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
        />
        <TextField
          sx={{ mt: 1, mb: 1 }}
          label="Website"
          type="text"
          fullWidth
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        <TextField
          sx={{ mt: 1, mb: 1 }}
          label="Company Size"
          select
          fullWidth
          value={companySize}
          onChange={(e) => setCompanySize(e.target.value)}
        >
          {companySizeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          startIcon={(loading && <CircularProgress size={16} />) || <></>}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrganizationDialog;
