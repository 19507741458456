import { ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import CompanyLogo from "../company/CompanyLogo";

export default ({
  product,
  onClick,
  sx = {},
}: {
  product: any;
  onClick: () => void;
  sx: any;
}) => {
  return (
    <ListItemButton sx={sx} onClick={onClick}>
      <ListItemAvatar>
        <CompanyLogo product={product} sx={{}} />
      </ListItemAvatar>
      <ListItemText
        primary={product.name}
        secondary={product.categories.map((x) => x.name).join(", ")}
      />
    </ListItemButton>
  );
};
