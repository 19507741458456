import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";

export const NotificationsMenu = ({
  notifications,
  open,
  anchorEl,
  onClose,
}: {
  notifications: Array<any>;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    onClick={onClose}
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
  >
    {notifications.map((notification) => (
      <MenuItem key={notification.id}>
        <Typography variant="subtitle1">{notification.title}</Typography>
        <Typography variant="body2">{notification.body}</Typography>
      </MenuItem>
    ))}
    {notifications.length === 0 && (
      <MenuItem>
        <Typography variant="subtitle1">No new notifications</Typography>
      </MenuItem>
    )}
  </Menu>
);
