import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import BackendApi from "../BackendApi";

export default ({
  session,
  backendApi,
  activeOrgId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: 1,
    name:
      session && session.user.user_metadata
        ? session.user.user_metadata.name
        : "",
    keywords: "CPQ, CRM, ERP",
    projects: [
      {
        details: "Xactly Implementation at Google",
        link: "https://project-link-google.com",
      },
      {
        details: "Migration to Salesforce for Facebook",
        link: "https://project-link-facebook.com",
      },
      {
        details: "Custom ERP Development for Reddit",
        link: "https://project-link-reddit.com",
      },
    ],
  });

  useEffect(() => {
    setFormData({
      id: 1,
      name:
        session && session.user.user_metadata
          ? session.user.user_metadata.name
          : "",
      keywords: "CPQ, CRM, ERP",
      projects: [
        {
          details: "Xactly Implementation at Google",
          link: "https://project-link-google.com",
        },
        {
          details: "Migration to Salesforce for Facebook",
          link: "https://project-link-facebook.com",
        },
        {
          details: "Custom ERP Development for Reddit",
          link: "https://project-link-reddit.com",
        },
      ],
    });
  }, [session]);

  const handleChange = (e, field, index) => {
    if (field.includes("projects")) {
      const updatedProjects = [...formData.projects];
      updatedProjects[index][field.split(".")[1]] = e.target.value;
      setFormData({ ...formData, projects: updatedProjects });
    } else {
      setFormData({ ...formData, [field]: e.target.value });
    }
  };

  const handleSave = () => {
    console.log("save");
  };

  return (
    <Box sx={{ p: 3 }}>
      <IconButton>
        <Avatar
          alt={
            session && session.user.user_metadata
              ? session.user.user_metadata.name
              : ""
          }
          src={
            session && session.user.user_metadata
              ? session.user.user_metadata.avatar_url
              : ""
          }
        />
      </IconButton>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        value={formData.name}
        onChange={(e) => handleChange(e, "name", null)}
      />
      <TextField
        margin="dense"
        id="keywords"
        label="Keywords"
        type="text"
        fullWidth
        variant="standard"
        value={formData.keywords}
        onChange={(e) => handleChange(e, "keywords", null)}
      />
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {formData.projects.map((project, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                margin="dense"
                id={`project-detail-${index}`}
                label="Project Detail"
                type="text"
                fullWidth
                variant="standard"
                value={project.details}
                onChange={(e) => handleChange(e, "projects.details", index)}
              />
              <TextField
                margin="dense"
                id={`project-link-${index}`}
                label="Project Link"
                type="text"
                fullWidth
                variant="standard"
                value={project.link}
                onChange={(e) => handleChange(e, "projects.link", index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button onClick={handleSave} color="primary">
        Save
      </Button>
    </Box>
  );
};
